import * as React from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import CategoryIcon from '@mui/icons-material/Category'
import WidgetsIcon from '@mui/icons-material/Widgets'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import BlurOnIcon from '@mui/icons-material/BlurOn'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import BusinessIcon from '@mui/icons-material/Business'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import SouthEastIcon from '@mui/icons-material/SouthEast'
import InventoryIcon from '@mui/icons-material/Inventory'
import MoveDownIcon from '@mui/icons-material/MoveDown'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import GroupsIcon from '@mui/icons-material/Groups'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import AssignmentIcon from '@mui/icons-material/Assignment'

import NavLink from './NavLink'
import Protected from './Protected'
import { Collapse, List } from '@mui/material'

export const mainListItems = (
  <React.Fragment>
    <Protected validPermissions={[1]}>
      <ListItemButton to="/dashboard" component={NavLink}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Resumen" />
      </ListItemButton>
    </Protected>

    <Protected validPermissions={[2]}>
      <ListItemButton to="/entradas/add" component={NavLink}>
        <ListItemIcon>
          <SouthEastIcon />
        </ListItemIcon>
        <ListItemText primary="Ingreso Inventario" />
      </ListItemButton>
    </Protected>

    <Protected validPermissions={[3, 4, 5, 6, 7, 48]}>
      <ListItemButton to="/solicitudes" component={NavLink} end>
        <ListItemIcon>
          <BookmarksIcon />
        </ListItemIcon>
        <ListItemText primary="Solicitudes" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[48]}>
      <ListItemButton to="/solicitudes/add" component={NavLink}>
        <ListItemIcon>
          <BookmarkAddIcon />
        </ListItemIcon>
        <ListItemText primary="Nueva solicitud" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[48]}>
      <ListItemButton to="/solicitudes/xls" component={NavLink}>
        <ListItemIcon>
          <BookmarkAddIcon />
        </ListItemIcon>
        <ListItemText primary="Nueva solicitud XLS" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[73, 74]}>
      <ListItemButton to="/despachos" component={NavLink}>
        <ListItemIcon>
          <LocalShippingIcon />
        </ListItemIcon>
        <ListItemText primary="Despachos" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[71]}>
      <ListItemButton to="/reducir_inventario" component={NavLink}>
        <ListItemIcon>
          <DeleteSweepIcon />
        </ListItemIcon>
        <ListItemText primary="Reducir Inventarios" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[72]}>
      <ListItemButton to="/traspaso_inventario" component={NavLink}>
        <ListItemIcon>
          <MoveDownIcon />
        </ListItemIcon>
        <ListItemText primary="Traspaso Inventarios" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[92, 93, 94, 95, 96]}>
      <ListItemButton to="/toma_inventario" component={NavLink}>
        <ListItemIcon>
          <FactCheckIcon />
        </ListItemIcon>
        <ListItemText primary="Toma de Inventario" />
      </ListItemButton>
    </Protected>
  </React.Fragment>
)

export const SecondaryListItems = () => {
  const [openStock, setOpenStock] = React.useState(false)

  const toggleStock = () => setOpenStock(!openStock)
  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Reportes
      </ListSubheader>

      <Protected validPermissions={[8]}>
        <ListItemButton to="/reportes/salidas" component={NavLink}>
          <ListItemIcon>
            <ArrowOutwardIcon />
          </ListItemIcon>
          <ListItemText primary="Salidas" />
        </ListItemButton>
      </Protected>

      <Protected validPermissions={[9]}>
        <ListItemButton to="/reportes/entradas" component={NavLink}>
          <ListItemIcon>
            <SouthEastIcon />
          </ListItemIcon>
          <ListItemText primary="Entradas" />
        </ListItemButton>
      </Protected>
      <Protected validPermissions={[51]}>
        <ListItemButton to="/reportes/desviaciones" component={NavLink}>
          <ListItemIcon>
            <InventoryIcon />
          </ListItemIcon>
          <ListItemText primary="Desviaciones" />
        </ListItemButton>
      </Protected>
      <Protected validPermissions={[10, 49, 50, 51]}>
        <ListItemButton onClick={toggleStock}>
          <ListItemIcon>
            <InventoryIcon />
          </ListItemIcon>
          <ListItemText primary="Stock" />
          {openStock ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openStock} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Protected validPermissions={[10]}>
              <ListItemButton
                to="/reportes/stock"
                component={NavLink}
                sx={{ pl: 4 }}>
                <ListItemIcon>
                  <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary="Actual" />
              </ListItemButton>
            </Protected>
            <Protected validPermissions={[49]}>
              <ListItemButton
                to="/reportes/stockBodegas"
                component={NavLink}
                sx={{ pl: 4 }}>
                <ListItemIcon>
                  <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary="Bodegas" />
              </ListItemButton>
            </Protected>
            <Protected validPermissions={[50]}>
              <ListItemButton
                to="/reportes/stockCanales"
                component={NavLink}
                sx={{ pl: 4 }}>
                <ListItemIcon>
                  <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary="Canales" />
              </ListItemButton>
            </Protected>
            <Protected validPermissions={[51]}>
              <ListItemButton
                to="/reportes/stockDetalle"
                component={NavLink}
                sx={{ pl: 4 }}>
                <ListItemIcon>
                  <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary="Detalle" />
              </ListItemButton>
            </Protected>
          </List>
        </Collapse>
      </Protected>

      <Protected validPermissions={[90]}>
        <ListItemButton to="/reportes/demanda" component={NavLink}>
          <ListItemIcon>
            <StackedLineChartIcon />
          </ListItemIcon>
          <ListItemText primary="Demanda" />
        </ListItemButton>
      </Protected>
      <Protected validPermissions={[99]}>
        <ListItemButton to="/reportes/top_demanda" component={NavLink}>
          <ListItemIcon>
            <StackedLineChartIcon />
          </ListItemIcon>
          <ListItemText primary="Top Demanda" />
        </ListItemButton>
      </Protected>
      <Protected validPermissions={[91]}>
        <ListItemButton to="/reportes/stockCritico" component={NavLink}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Stock Critico" />
        </ListItemButton>
      </Protected>
    </React.Fragment>
  )
}
export const adminListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Maestros
    </ListSubheader>
    <Protected validPermissions={[43, 44, 45, 46, 60]}>
      <ListItemButton to="/perfiles" component={NavLink}>
        <ListItemIcon>
          <SupervisedUserCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Perfiles" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[11, 12, 13, 14, 52]}>
      <ListItemButton to="/users" component={NavLink}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Usuarios" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[15, 16, 17, 18, 53]}>
      <ListItemButton to="/categorias" component={NavLink}>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary="Categorías" />
      </ListItemButton>
    </Protected>

    <Protected validPermissions={[19, 20, 21, 22, 54]}>
      <ListItemButton to="/sub_categorias" component={NavLink}>
        <ListItemIcon>
          <ScatterPlotIcon />
        </ListItemIcon>
        <ListItemText primary="Sub Categorías" />
      </ListItemButton>
    </Protected>

    <Protected validPermissions={[23, 24, 25, 26, 55]}>
      <ListItemButton to="/marcas" component={NavLink}>
        <ListItemIcon>
          <BlurOnIcon />
        </ListItemIcon>
        <ListItemText primary="Marcas" />
      </ListItemButton>
    </Protected>

    <Protected validPermissions={[27, 28, 29, 30, 56]}>
      <ListItemButton to="/centros_costo" component={NavLink}>
        <ListItemIcon>
          <CurrencyExchangeIcon />
        </ListItemIcon>
        <ListItemText primary="Centros Costo" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[80, 81, 82, 83, 84]}>
      <ListItemButton to="/cadenas" component={NavLink}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Cadenas" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[31, 32, 33, 34, 57]}>
      <ListItemButton to="/tiendas" component={NavLink}>
        <ListItemIcon>
          <HolidayVillageIcon />
        </ListItemIcon>
        <ListItemText primary="Tiendas" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[35, 36, 37, 38, 59]}>
      <ListItemButton to="/bodegas" component={NavLink}>
        <ListItemIcon>
          <WarehouseIcon />
        </ListItemIcon>
        <ListItemText primary="Bodegas" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[66, 67, 68, 69, 70]}>
      <ListItemButton to="/proveedores" component={NavLink}>
        <ListItemIcon>
          <GroupsIcon />
        </ListItemIcon>
        <ListItemText primary="Proveedores" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[39, 40, 41, 42, 47]}>
      <ListItemButton to="/productos" component={NavLink}>
        <ListItemIcon>
          <WidgetsIcon />
        </ListItemIcon>
        <ListItemText primary="Productos" />
      </ListItemButton>
    </Protected>
    <Protected validPermissions={[75, 76, 77, 78, 79]}>
      <ListItemButton to="/canales_dist" component={NavLink}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Canales" />
      </ListItemButton>
    </Protected>
  </React.Fragment>
)
